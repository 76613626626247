.toastmsgbg
{
  max-width: 440px;
  min-height: 30px;
  z-index: 20;
  position: fixed;
  bottom: 70px;
  background-color: rgba(0,0,0,0.8);
  pointer-events: none;
  padding: 10px;
  border-radius: 10px;

  animation-duration: 2.1s;
  animation-name: toastani;
}

@keyframes toastani {
  0% {
    opacity: 0;
  }
  
  30% {
    opacity: 1;
  }
  
  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}