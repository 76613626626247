
.setbg
{
  width: 100%;
  background-color: white;
}

.setcontbg
{
  width: 1200px;
  margin-top: 70px;
}

.setcont
{
  width: 585px;
  box-sizing: border-box;
}

.setcont2
{
  width: 100%;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.setcont_mb
{
  width: 316px;
  margin-top: 45px;
}

.setinp
{
  width: 91%;
  height: 45px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 15px;
}

.setinp::placeholder
{
  font-size: 16px;
  font-weight: 400;
  color:#B3B3B3;
  font-family: 'noto';
}

.setinp_mb
{
  width: 316px;
  height: 40px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 15px;
}

.setinp_mb::placeholder
{
  font-size: 14px;
  font-weight: 400;
  color:#B3B3B3;
  font-family: 'noto';
}

.setbox
{
  width: 91%;
  height: 45px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 15px;
}

.setbox_mb
{
  width: 316px;
  height: 40px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 15px;
}

.setbox2
{
  width: 91%;
  height: 45px;
  background-color: #F2F2F280;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 15px;
}

.setbtn
{
  width: 100px;
  height: 45px;
  border: none;
  border-radius: 5px;
}

.setbtn_mb
{
  width: 316px;
  height: 40px;
  border: none;
  background-color: #2056A5;
  border-radius: 5px;
}

.uqbg
{
  width: 500px;
  margin-top: 48px;
}

.uqbg_mb
{
  width: 316px;
  margin-top: 48px;
  margin-bottom: 50px;
}

.uqpop
{
  width: 100%;
  height: 655px;
  box-shadow: 0px 0px 15px 0px #0000001A;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.uqpop_mb
{
  width: 100%;
  padding-top: 10px;
}

.uqpoptab
{
  width: 100%;
  height: 45px;
  background-color: #4BB4DE26;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.uqpoptabin
{
  width: 90%;
  height: 45px;
}

.uqtab1
{
  width: 25%;
  height: 100%;
}

.uqtab2
{
  width: 57%;
  height: 100%;
}

.uqtab3
{
  width: 12%;
  height: 100%;
}

.uqitemin
{
  width: 100%;
}

.uqitem
{
  width: 100%;
}

.uqitem_mb
{
  width: 100%;
  height: 62px;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  margin-top: 10px;
}

.uqitemcont
{
  width: 90%;
  height: 50px;
}

.uqitemcont_mb
{
  width: 94%;
}

.uqitemline
{
  width: 99%;
  height: 1px;
  background-color: #DADCE2;
}

.uqitemlinemb
{
  width: 100%;
  height: 1px;
  background-color: #DADCE2;
}

.uqdetbg
{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 7;
  background-color: rgba(0,0,0,0.5);
  left: 0px;
  top: 0px;
}

.uqdetbg_mb
{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 7;
  background-color: white;
  left: 0px;
  top: 0px;
}

.uqdetpop
{
  width: 500px;
  height: 475px;
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
  padding-top: 20px;
}

.uqdetpopmb
{
  width: 316px;
}

.uqdetpopin
{
  width: 450px;
}

.uqdetpopinmb
{
  width: 450px;
}

.uqdetqt
{
  width: 450px;
  height: 160px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 10px;
}

.uqdetqtmb
{
  width: 100%;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
}

.uqdetans
{
  width: 450px;
  height: 160px;
  border-radius: 5px;
  background-color: #4BB4DE26;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 10px;
}

.uqdetansmb
{
  width: 100%;
  min-height: 100px;
  border-radius: 5px;
  background-color: #4BB4DE26;
  box-sizing: border-box;
  padding: 10px;
}

.uqdettt
{
  width: 100%;
  height: 45px;
}

.uqdetttin
{
  width: 316px;
  height: 100%;
}

.unregbtnarea
{
  width: 88%;
}