.scroll-down {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scroll {
  width: 2rem;
  height: 4rem;
  border: 2px solid #2056A5;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll span {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background: #2056A5;
  display: block;
  animation: cursor 1.15s linear infinite;
}

.arrow-down {
  width: 0.8rem;
  height: 0.8rem;
  border: 2px solid transparent;
  border-right-color: black;
  border-bottom-color: black;
  transform: rotate(45deg);
  margin-top: 0.3rem;
  animation: arrow 1.15s linear infinite;
}

@keyframes cursor {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }
  
  100% {
    opacity: 1;
    transform: translateY(1rem);
  }
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  
  100% {
    opacity: 1;
  }
}