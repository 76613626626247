
.loadingbg
{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  background-color: rgba(0,0,0,0.0);
  z-index: 100;
}

.loadingbg2
{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  background-color: rgba(0,0,0,0.5);
  z-index: 100;
}

.loadingimg
{
  width: 100px;
  height: 100px;
  object-fit: contain;
}