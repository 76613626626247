
.ntcbg
{
  width: 100%;
  background-color: white;
}

.ntccont
{
  width: 1200px;
  margin-top: 70px;
}

.ntccont_mb
{
  width: 316px;
  margin-top: 45px;
}

.ntcsearcharea
{
  width: 100%;
}

.ntcserinparea
{
  width: 500px;
}

.ntcserinparea_mb
{
  width: 316px;
}

.ntcinp
{
  width: 430px;
  height: 35px;
  border: none;
  padding-left: 15px;
  box-sizing: border-box;
}

.ntcinp::placeholder
{
  border: none;
  font-size: 14px;
  color: #B3B3B3;
  font-weight: 400;
  font-family: 'noto';
}

.ntcinp:focus
{
  outline: none;
}

.ntcinp_mb
{
  width: 230px;
  height: 35px;
  border: none;
  padding-left: 15px;
  box-sizing: border-box;
}

.ntcinp_mb::placeholder
{
  border: none;
  font-size: 14px;
  color: #B3B3B3;
  font-weight: 400;
  font-family: 'noto';
}

.ntcinp_mb:focus
{
  outline: none;
}

.ntcserline
{
  width: 100%;
  height: 1px;
  background-color: #2056A5;
}

.ntcbtn1
{
  width: 74px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #2056A5;
  background-color: white;
}

.ntcbtn1_mb
{
  width: 70px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid #2056A5;
  background-color: white;
}

.ntcbtn2
{
  width: 74px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  background-color: white;
}

.ntcbtn2_mb
{
  width: 70px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  background-color: white;
}

.ntcitemarea
{
  width: 100%;
}

.ntcitem
{
  width: 100%;
  min-height: 50px;
}

.ntcitem2
{
  width: 100%;
  min-height: 95px;
}

.ntcitemline
{
  width: 100%;
  height: 1px;
  background-color: #DADCE2;
}

.ntctag1
{
  width: 40px;
  height: 20px;
  border-radius: 10px;
  background-color: #2056A5;
}

.ntctag1_mb
{
  width: 30px;
  height: 15px;
  border-radius: 10px;
  background-color: #2056A5;
}

.ntctag2
{
  width: 40px;
  height: 20px;
  border-radius: 10px;
  background-color: #F9D230;
}

.ntctag2_mb
{
  width: 30px;
  height: 15px;
  border-radius: 10px;
  background-color: #F9D230;
}

.ntctag3
{
  width: 40px;
  height: 20px;
}

.ntctag3_mb
{
  width: 30px;
  height: 15px;
}

.ntcans
{
  width: 230px;
}

.ntcans_pc
{
  width: 1110px;
}

.ntctable
{
  width: 100%;
  background-color: #2056A51A;
  text-align: center;
  border-collapse: collapse;
  border : none;
  box-sizing: border-box;
}

.ntctbcell
{
  min-height: 45px;
  height: 45px;
  border-left-width: 0px;
  border-top-width: 1px;
  border-bottom: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-color: #B3B3B3;
  box-sizing: border-box;
  text-align: center;
}

.ntctbcell2
{
  min-height: 45px;
  height: 45px;
  border: 1px solid #B3B3B3;
  box-sizing: border-box;
  text-align: center;
}

.ntctbcell3
{
  min-height: 45px;
  height: 45px;
  border-left-width: 1px;
  border-top-width: 1px;
  border-bottom: 1px;
  border-right-width: 0px;
  border-style: solid;
  border-color: #B3B3B3;
  box-sizing: border-box;
  text-align: center;
}

.ntctbrow
{
  width: 100%;
  background-color: white;
  text-align: center;
  box-sizing: border-box;
}

.ntctbrowitem
{
  border-left-width: 0px;
  border-top-width: 1px;
  border-bottom: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-color: #B3B3B3;
}

.ntctbrowitem2
{
  width: 83%;
  border-left-width: 1px;
  border-top-width: 1px;
  border-bottom: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-color: #B3B3B3;
}

.ntctbrowitem3
{
  border-left-width: 1px;
  border-top-width: 1px;
  border-bottom: 1px;
  border-right-width: 0px;
  border-style: solid;
  border-color: #B3B3B3;
}

.ntctbrowdiv
{
  width: 100%;
  min-height: 45px;
  background-color: white;
  box-sizing: border-box;
}