
.talkbg
{
  width: 100px;
  height: 100px;
  position: fixed;
  z-index: 5;
  right: 60px;
  bottom: 105px;
}

.talkbg_mb
{
  width: 50px;
  height: 50px;
  position: fixed;
  z-index: 5;
  right: 20px;
  bottom: 20px;
}