
.loginbg
{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 5;
  background-color: rgba(0,0,0,0.5);
  top: 0px;
}

.loginpop
{
  width: 500px;
  height: auto;
  background-color: white;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.loginpop_mb
{
  width: 100%;
  height: 100%;
  background-color: white;
}

.loincont
{
  width: 450px;
}

.loincont_mb
{
  width: 316px;
  height: 100%;
}

.logincont2
{
  width: 100%;
}

.loginline
{
  width: 100%;
  height: 1px;
  background-color: #DADCE2;
}
.loginconttop_mb
{
  width: 316px;
  height: 45px;
}

.logininp
{
  width: 450px;
  min-height: 45px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  background-color: white;
  box-sizing: border-box;
  padding-left: 10px;
}

.logininp::placeholder
{
  font-size: 16px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: 'noto';
}

.logininp_mb
{
  width: 316px;
  min-height: 40px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  padding-left: 10px;
}

.logininp_mb::placeholder
{
  font-size: 16px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: 'noto';
}

.loginbtn
{
  width: 450px;
  height: 45px;
  border: none;
  background-color: #2056A5;
  border-radius: 5px;
}

.loginbtnx2
{
  width: 450px;
  height: 45px;
  border: none;
  background-color: #4BB4DE26;
  border-radius: 5px;
}

.loginbtn_mb
{
  width: 316px;
  height: 40px;
  border: none;
  background-color: #2056A5;
  border-radius: 5px;
}

.loginbtn2
{
  width: 220px;
  height: 30px;
  border: none;
  background-color: #F2F2F280;
  border-radius: 5px;
}

.loginbtn2_mb
{
  width: 153px;
  height: 25px;
  border: 1px solid #2056A5;
  background-color: white;
  border-radius: 5px;
  padding: 0;
}

.findidpop
{
  width: 500px;
  background-color: white;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.findidpop_mb
{
  width: 100%;
  height: 100%;
  background-color: white;
}

.findidpop_in
{
  width: 450px;
}

.findidpop_in_mb
{
  width: 315px;
  margin-top: 20px;
}

.findidinp1
{
  width: 360px;
  height: 45px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #DADCE2;
  padding-left: 15px;
}

.findidinp1::placeholder
{
  font-size: 14px;
  font-weight: 400;
  color: #B3B3B3;
}

.findidinp1_mb
{
  width: 230px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #DADCE2;
  padding-left: 15px;
}

.findidinp1_mb::placeholder
{
  font-size: 14px;
  font-weight: 400;
  color: #B3B3B3;
}

.findidinp2
{
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #DADCE2;
  padding-left: 15px;
}

.findidinp2::placeholder
{
  font-size: 14px;
  font-weight: 400;
  color: #B3B3B3;
}

.findidinp2_mb
{
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #DADCE2;
  padding-left: 15px;
}

.findidinp2_mb::placeholder
{
  font-size: 14px;
  font-weight: 400;
  color: #B3B3B3;
}

.findidbtn1
{
  width: 80px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #2056A5;
  background-color: white;
}

.findidbtn1_mb
{
  width: 80px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #2056A5;
  background-color: white;
}

.findidbtn2
{
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: none;
  background-color: #2056A5;
}

.findidbtn3
{
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: none;
  background-color: #B3B3B3;
}

.findidbtn2_mb
{
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: #2056A5;
}

.findidbtn3_mb
{
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: #B3B3B3;
}

.findidline
{
  width: 100%;
  height: 1px;
  background-color: #2056A5;
}

.findidbg
{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 4;
  background-color: rgba(0,0,0,0.5);
  top: 0px;
}

.accsaveinp
{
  width: 15px;
  height: 15px;
}

.findidbox
{
  width: 100%;
  height: 45px;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
}

.findidbtn3
{
  width: 100%;
  height: 45px;
  border-radius: 5px;
  background-color: #4BB4DE26;
}