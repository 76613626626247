.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'gmarket';
  src: url('https://cdn.snsfly.kr/cashp/fonts/GmarketSansMedium.otf') format('opentype');
}

@font-face {
  font-family: 'leferi';
  src: url('https://cdn.snsfly.kr/cashp/fonts/LeferiBaseRegular.otf') format('opentype');
}

@font-face {
  font-family: 'noto';
  src: url('https://cdn.snsfly.kr/cashp/fonts/NotoSansKR-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'fontpd';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
}

.flexrowcc
{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.rowcc
{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flexrowce
{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.rowce
{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.rowcr
{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.flexrowcs
{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.rowcl
{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.flexrowsc
{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rowsc
{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flexrowss
{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.rowsl
{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.flexrowse
{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.rowsr
{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.flexrowlc
{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.rowlc
{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flexrowls
{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.rowlt
{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.flexrowrc
{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.rowrc
{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flexrowevenc
{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.rowec
{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.rowlb
{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.flexcolsl
{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.colsl
{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.flexcolsc
{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.colsc
{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flexcolcl
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.colcl
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.flexcolcr
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.colcr
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.flexcolcc
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.colcc
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flexcoltl
{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.coltl
{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flexcoltr
{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.coltr
{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.flexcoltc
{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.coltc
{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flexcolel
{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.colel
{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.flexcolec
{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.colec
{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.flexcolevenl
{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.colevl
{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.flexcolevenc
{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.colevc
{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.lettersp0
{
  letter-spacing: 0em;
}

.lineheight20
{
  line-height: 20px;
}

.lineheight24
{
  line-height: 24px;
}

.lineheight28
{
  line-height: 28px;
}

.lineheight30
{
  line-height: 30px;
}

.lht20
{
  line-height: 20px;
}

.lht23
{
  line-height: 23px;
}

.lht24
{
  line-height: 24px;
}

.lht28
{
  line-height: 28px;
}

.lht30
{
  line-height: 30px;
}

.lht38
{
  line-height: 38px;
}

.lht52
{
  line-height: 52px;
}

.fontunderline
{
  text-decoration: underline;
}

.font9w_1
{
  font-size: 9px;
  font-weight: 400;
  color: white;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font9
{
  font-size: 9px;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font10
{
  font-size: 10px;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font10b
{
  font-size: 10px;
  font-weight: 400;
  color: #313131;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font10w_1
{
  font-size: 10px;
  font-weight: 400;
  color: white;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font1020
{
  font-size: 10px;
  font-weight: 500;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font10b3
{
  font-size: 10px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font12
{
  font-size: 12px;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font12w
{
  font-size: 12px;
  font-weight: 400;
  color: white;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font12b
{
  font-size: 12px;
  font-weight: 400;
  color: #313131;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font12b2
{
  font-size: 12px;
  font-weight: 500;
  color: #313131;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font1220
{
  font-size: 12px;
  font-weight: 400;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font12202
{
  font-size: 12px;
  font-weight: 500;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font1280
{
  font-size: 12px;
  font-weight: 400;
  color: #808080;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font12b3
{
  font-size: 12px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font124b
{
  font-size: 12px;
  font-weight: 500;
  color: #4BB4DE;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font12ff
{
  font-size: 12px;
  font-weight: 400;
  color: #FF0000;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font13
{
  font-size: 13px;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font13btn1
{
  font-size: 13px;
  font-weight: 500;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font13b
{
  font-size: 13px;
  font-weight: 400;
  color: #313131;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font14gm
{
  font-size: 14px;
  font-weight: 700;
  color: #4BB4DE;
  font-family: 'gmarket';
}

.font14
{
  font-size: 14px;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font14btn1
{
  font-size: 14px;
  font-weight: 700;
  color: white;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font14btn2
{
  font-size: 14px;
  font-weight: 700;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font14btn3
{
  font-size: 14px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font14btn4
{
  font-size: 14px;
  font-weight: 400;
  color: white;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font14btn5
{
  font-size: 14px;
  font-weight: 500;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font14btn6
{
  font-size: 14px;
  font-weight: 500;
  color: white;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font14b_1
{
  font-size: 14px;
  font-weight: 700;
  color: #313131;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font14b_2
{
  font-size: 14px;
  font-weight: 400;
  color: #313131;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font1420
{
  font-size: 14px;
  font-weight: 500;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font14202
{
  font-size: 14px;
  font-weight: 400;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font14203
{
  font-size: 14px;
  font-weight: 700;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font144b
{
  font-size: 14px;
  font-weight: 400;
  color: #4BB4DE;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font1480
{
  font-size: 14px;
  font-weight: 400;
  color: #808080;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font14b3
{
  font-size: 14px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font14ff
{
  font-size: 14px;
  font-weight: 300;
  color: #FF0000;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font14ff2
{
  font-size: 14px;
  font-weight: 400;
  color: #FF0000;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font14w
{
  font-size: 14px;
  font-weight: 500;
  color: white;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font14w2
{
  font-size: 14px;
  font-weight: 400;
  color: white;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font15
{
  font-size: 15px;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font1520
{
  font-size: 14px;
  font-weight: 600;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font16
{
  font-size: 16px;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font16btn
{
  font-size: 16px;
  font-weight: 700;
  color: white;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font16btn2
{
  font-size: 16px;
  font-weight: 700;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font16btn3
{
  font-size: 16px;
  font-weight: 400;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font16btn4
{
  font-size: 16px;
  font-weight: 500;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font16b
{
  font-size: 16px;
  font-weight: 400;
  color: #313131;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font16b_2
{
  font-size: 16px;
  font-weight: 500;
  color: #313131;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font1620
{
  font-size: 16px;
  font-weight: 500;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font16202
{
  font-size: 16px;
  font-weight: 400;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font16203
{
  font-size: 16px;
  font-weight: 700;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font16b3
{
  font-size: 16px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font164b
{
  font-size: 16px;
  font-weight: 500;
  color: #4BB4DE;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font18
{
  font-size: 18px;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font18b
{
  font-size: 18px;
  font-weight: 400;
  color: #313131;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font18b2
{
  font-size: 18px;
  font-weight: 500;
  color: #313131;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font1820
{
  font-size: 18px;
  font-weight: 500;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font19
{
  font-size: 19px;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font19b
{
  font-size: 19px;
  font-weight: 500;
  color: #313131;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font20
{
  font-size: 20px;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font20b
{
  font-size: 20px;
  font-weight: 400;
  color: #313131;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font20b2
{
  font-size: 20px;
  font-weight: 500;
  color: #313131;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font20b3
{
  font-size: 20px;
  font-weight: 700;
  color: #313131;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font2020
{
  font-size: 20px;
  font-weight: 700;
  color: #2056A5;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font204b
{
  font-size: 20px;
  font-weight: 700;
  color: #4BB4DE;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font20w
{
  font-size: 20px;
  font-weight: 400;
  color: white;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font22
{
  font-size: 22px;
  font-family: 'gmarket';
}

.font224b
{
  font-size: 22px;
  font-weight: 700;
  color: #4BB4DE;
  font-family: 'gmarket';
}

.font24
{
  font-size: 24px;
  font-family: 'leferi';
}

.font2420
{
  font-size: 24px;
  font-weight: 700;
  color: #2056A5;
  font-family: 'leferi';
}

.font28
{
  font-size: 28px;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font28w
{
  font-size: 28px;
  font-weight: 400;
  color: white;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font28b
{
  font-size: 28px;
  font-weight: 400;
  color: #313131;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

.font42
{
  font-size: 42px;
  font-family: 'leferi';
}

.font4220
{
  font-size: 42px;
  font-weight: 700;
  color: #2056A5;
  font-family: 'leferi';
}

.fontpd20
{
  font-size: 20px;
  font-family: 'fontpd';
}

.fontpd28
{
  font-size: 28px;
  font-family: 'fontpd';
}

.fontpd42
{
  font-size: 42px;
  font-family: 'fontpd';
}

.fontpd52
{
  font-size: 52px;
  font-family: 'fontpd';
}

.marginleft5
{
  margin-left: 5px;
}

.marginleft10
{
  margin-left: 10px;
}

.marginleft15
{
  margin-left: 15px;
}

.marginleft20
{
  margin-left: 20px;
}

.marginleft25
{
  margin-left: 25px;
}

.mglt5
{
  margin-left: 5px;
}

.mglt10
{
  margin-left: 10px;
}

.mglt15
{
  margin-left: 15px;
}

.mglt20
{
  margin-left: 20px;
}

.mglt25
{
  margin-left: 25px;
}

.mglt30
{
  margin-left: 30px;
}

.mglt40
{
  margin-left: 40px;
}

.mglt50
{
  margin-left: 50px;
}

.mglt60
{
  margin-left: 40px;
}

.mglt180
{
  margin-left: 180px;
}

.marginright5
{
  margin-right: 5px;
}

.marginright10
{
  margin-right: 10px;
}

.marginright15
{
  margin-right: 15px;
}

.marginright20
{
  margin-right: 20px;
}

.marginright25
{
  margin-right: 25px;
}

.marginright30
{
  margin-right: 30px;
}

.marginright50
{
  margin-right: 50px;
}

.marginright70
{
  margin-right: 70px;
}

.mgrt5
{
  margin-right: 5px;
}

.mgrt10
{
  margin-right: 10px;
}

.mgrt15
{
  margin-right: 15px;
}

.mgrt20
{
  margin-right: 20px;
}

.mgrt25
{
  margin-right: 25px;
}

.mgrt30
{
  margin-right: 30px;
}

.mgrt40
{
  margin-right: 40px;
}

.mgrt50
{
  margin-right: 50px;
}

.mgrt70
{
  margin-right: 70px;
}

.margintop2
{
  margin-top: 2px;
}

.margintop3
{
  margin-top: 3px;
}

.margintop5
{
  margin-top: 5px;
}

.margintop8
{
  margin-top: 8px;
}

.margintop10
{
  margin-top: 10px;
}

.margintop15
{
  margin-top: 15px;
}

.margintop17
{
  margin-top: 17px;
}

.margintop20
{
  margin-top: 20px;
}

.margintop22
{
  margin-top: 22px;
}

.margintop25
{
  margin-top: 25px;
}

.margintop28
{
  margin-top: 28px;
}

.margintop30
{
  margin-top: 30px;
}

.margintop35
{
  margin-top: 35px;
}

.margintop40
{
  margin-top: 40px;
}

.margintop42
{
  margin-top: 42px;
}

.margintop48
{
  margin-top: 48px;
}

.margintop50
{
  margin-top: 50px;
}

.margintop55
{
  margin-top: 55px;
}

.margintop60
{
  margin-top: 60px;
}

.margintop70
{
  margin-top: 70px;
}

.margintop80
{
  margin-top: 80px;
}

.margintop120
{
  margin-top: 120px;
}

.margintop140
{
  margin-top: 140px;
}

.mgtop2
{
  margin-top: 2px;
}

.mgtop3
{
  margin-top: 3px;
}

.mgtop5
{
  margin-top: 5px;
}

.mgtop8
{
  margin-top: 8px;
}

.mgtop10
{
  margin-top: 10px;
}

.mgtop15
{
  margin-top: 15px;
}

.mgtop17
{
  margin-top: 17px;
}

.mgtop20
{
  margin-top: 20px;
}

.mgtop22
{
  margin-top: 22px;
}

.mgtop25
{
  margin-top: 25px;
}

.mgtop28
{
  margin-top: 28px;
}

.mgtop30
{
  margin-top: 30px;
}

.mgtop35
{
  margin-top: 35px;
}

.mgtop40
{
  margin-top: 40px;
}

.mgtop42
{
  margin-top: 42px;
}

.mgtop45
{
  margin-top: 45px;
}

.mgtop48
{
  margin-top: 48px;
}

.mgtop50
{
  margin-top: 50px;
}

.mgtop55
{
  margin-top: 55px;
}

.mgtop60
{
  margin-top: 60px;
}

.mgtop70
{
  margin-top: 70px;
}

.mgtop80
{
  margin-top: 80px;
}

.mgtop100
{
  margin-top: 100px;
}

.mgtop120
{
  margin-top: 120px;
}

.mgtop140
{
  margin-top: 140px;
}

.mgtop150
{
  margin-top: 150px;
}

.mgtop160
{
  margin-top: 160px;
}

.mgtop180
{
  margin-top: 180px;
}

.mgtop200
{
  margin-top: 200px;
}

.marginbottom5
{
  margin-bottom: 5px;
}

.marginbottom10
{
  margin-bottom: 10px;
}

.marginbottom15
{
  margin-bottom: 15px;
}

.marginbottom20
{
  margin-bottom: 20px;
}

.marginbottom25
{
  margin-bottom: 25px;
}

.marginbottom50
{
  margin-bottom: 50px;
}

.marginbottom60
{
  margin-bottom: 60px;
}

.marginbottom70
{
  margin-bottom: 70px;
}

.marginbottom100
{
  margin-bottom: 100px;
}

.marginbottom150
{
  margin-bottom: 150px;
}

.marginbottom200
{
  margin-bottom: 200px;
}

.mgbt5
{
  margin-bottom: 5px;
}

.mgbt10
{
  margin-bottom: 10px;
}

.mgbt15
{
  margin-bottom: 15px;
}

.mgbt20
{
  margin-bottom: 20px;
}

.mgbt25
{
  margin-bottom: 25px;
}

.mgbt50
{
  margin-bottom: 50px;
}

.mgbt60
{
  margin-bottom: 60px;
}

.mgbt70
{
  margin-bottom: 70px;
}

.mgbt100
{
  margin-bottom: 100px;
}

.mgbt150
{
  margin-bottom: 150px;
}

.mgbt200
{
  margin-bottom: 200px;
}

.wd50p
{
  width: 50%;
}

.wd90p
{
  width: 90%;
}

.wd91p
{
  width: 91%;
}

.wd95p
{
  width: 95%;
}

.wd100p
{
  width: 100%;
}

.fullwidth
{
  width: 100%;
}

.fullheight
{
  height: 100%;
}

.btncur
{
  cursor: pointer;
}

.textcenter
{
  text-align: center;
}

.textleft
{
  text-align: left;
}

.menubtn:hover
{
  scale: 1.05;
}

.img107
{
  width: 10px;
  height: 7px;
}

.img1414
{
  width: 14px;
  height: 14px;
}

.img1511
{
  width: 15px;
  height: 11px;
}

.img1515
{
  width: 15px;
  height: 15px;
}

.img1713
{
  width: 17px;
  height: 13px;
}

.img2020
{
  width: 20px;
  height: 20px;
}

.img2222
{
  width: 22px;
  height: 22px;
}

.img2514
{
  width: 25px;
  height: 14px;
}

.img2828
{
  width: 28px;
  height: 28px;
}

.img3424
{
  width: 34px;
  height: 24px;
}

.img4545
{
  width: 45px;
  height: 45px;
}

.img4951
{
  width: 49px;
  height: 51px;
}

.img5050
{
  width: 50px;
  height: 50px;
}

.img5251
{
  width: 52px;
  height: 51px;
}

.img5252
{
  width: 52px;
  height: 52px;
}

.img5634
{
  width: 56px;
  height: 34px;
}

.img5651
{
  width: 56px;
  height: 51px;
}

.img6060
{
  width: 60px;
  height: 60px;
}

.img7584
{
  width: 75px;
  height: 84px;
}

.img9421
{
  width: 94px;
  height: 21px;
}

.colorw
{
  color: white;
}

.color00
{
  color: #000000;
}

.color20
{
  color: #2056A5;
}

.color31
{
  color: #313131;
}

.color4b
{
  color: #4BB4DE;
}

.color66
{
  color: #666666;
}

.color80
{
  color: #808080;
}

.colorb3
{
  color: #B3B3B3;
}

.colore6
{
  color: #E6E6E6;
}

.colorf7
{
  color: #F7654a;
}

.colorff
{
  color: #FF0000;
}

.colorff36
{
  color: #FF3636;
}

.colorff96
{
  color: #FF9600;
}

.bgcolorb3
{
  background-color: #B3B3B3;
}

.bgcolor20
{
  background-color: #2056A5;
}

.wt300
{
  font-weight: 300;
}

.wt400
{
  font-weight: 400;
}

.wt500
{
  font-weight: 500;
}

.wt600
{
  font-weight: 600;
}

.wt700
{
  font-weight: 700;
}

.pgcont
{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.pgcont2
{
  width: 100%;
  box-sizing: border-box;
  margin-top: 70px;
}

.posrel
{
  position: relative;
}