.top_cont
{
  width: 100%;
  background-color: white;
  position: fixed;
  top: 0px;
  z-index: 3;
}

.top_bar
{
  width: 100%;
  height: 70px;
  min-height: 70px;
}

.top_bar_mb
{
  width: 316px;
  height: 45px;
  min-height: 45px;
}

.menutext:hover
{
  color: #2056A5;
  font-weight: 700;
}

.pointbox
{
  height: 30px;
  border: 1px solid #2056a5;
  border-radius: 0.75px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.pointbox_mb
{
  height: 25px;
  border: 1px solid #2056a5;
  border-radius: 0.75px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.topbarline
{
  width: 100%;
  height: 1px;
  background-color: #DADCE2;
}

.mmbg
{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0px;
  z-index: 4;
}

.mmtop
{
  width: 100%;
  height: 45px;
  background-color: white;
}

.mmtop_in
{
  width: 316px;
  height: 45px;
}

.mmmenu
{
  width: 100%;
  height: 105px;
  background-color: white;
}

.mmmenu_in
{
  width: 316px;
  height: 105px;
  background-color: white;
}

.mmmenu2
{
  width: 100%;
  height: 25px;
  background-color: #2056A5;
}