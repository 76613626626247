
.homecont
{
  background-color: white;
}

.homecont2
{
  width: 100%;
  margin-left: 40px;
  overflow: auto;
  box-sizing: border-box;
}

.homecont3
{
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
}

.homeleft
{
  width: 770px;
}

.homeright
{
  width: 400px;
  border-radius: 5px;
  border: 1px solid #F2F2F2;
  background-color: white;
  padding-top: 12px;
  padding-bottom: 12px;
}

.homerightcont
{
  width: 92%;
}

.homealarm
{
  width: 100%;
  height: 45px;
  border-radius: 5px;
  background-color: #FFC8001A;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}

.homentc
{
  width: 100%;
  height: 105px;
  border-radius: 5px;
  border: 1px solid #F2F2F2;
  background-color: white;
  margin-top: 20px;
  padding-top: 15px;
}

.homentcitem
{
  width: 96%;
  margin-bottom: 10px;
}

.homentcitemtag
{
  width: 50px;
  height: 25px;
  border-radius: 20px;
  background-color: #FFCE00;
  margin-right: 10px;
}

.homeadscont
{
  width: 150px;
  height: 110px;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
  background-color: white;
}

.homecpinfobtn
{
  width: 90px;
  height: 25px;
  background-color: #4BB4DE26;
  border: none;
  border-radius: 20px;
}

.homecpinfott
{
  width: 110px;
}

.homeiframe
{
  width: 100%;
  height: 100%;
  border: none;
  padding: 0px;
  margin: 0px;
}

.homem1
{
  width: 100%;
  min-height: 635px;
  overflow: hidden;
  padding: 0px;
  position: relative;
  background-color: #cdebf6;
}

.homem1img
{
  width: 1620px;
  height: 635px;
  min-height: 635px;
  object-fit: fill;
}

.homem1text
{
  position: absolute;
  top: 95px;
  z-index: 1;
}

.homem2
{
  width: 100%;
  min-height: 540px;
  overflow: hidden;
  padding: 0px;
  position: relative;
  background-color: #2056a5;
}

.homem2img
{
  width: 1620px;
  height: 540px;
  min-height: 540px;
  object-fit: fill;
}

.homem2text
{
  position: absolute;
  left: 238px;
  top: 95px;
  z-index: 1;
}

.homem3
{
  width: 100%;
  min-height: 300px;
  overflow: hidden;
  padding: 0px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.homem3img
{
  width: 1621px;
  min-height: 300px;
  height: 300px;
  object-fit: fill;
}

.homem3text
{
  position: absolute;
  z-index: 1;
}

.homem4
{
  width: 100%;
  padding: 0px;
}

.homem4in
{
  width: 1152px;
}

.home4img
{
  width: 560px;
  height: 420px;
  object-fit: contain;
}

.home4div
{
  width: 560px;
  height: 420px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.home4littiecont1
{
  width: 230px;
  height: 230px;
  position: absolute;
  bottom: 9px;
}

.home4littiecont2
{
  width: 220px;
  height: 72px;
  position: absolute;
  bottom: 13px;
}

.home7
{
  width: 100%;
  height: 530px;
  min-height: 530px;
  background-color: #F2F2F280;
}

.home7in
{
  width: 1105px;
}

.home7int1
{
  margin-left: 55px;
}

.home7int2
{
  margin-left: 50px;
}

.home7int3
{
  margin-left: 60px;
  margin-right: 75px;
}

