
.pagecont
{
  width: 100%;
}

.pagemargin
{
  margin-left: 15px;
  margin-right: 15px;
}