
.faqbg
{
  width: 100%;
  background-color: white;
}

.faqcont
{
  width: 1200px;
  margin-top: 70px;
}

.faqcont_mb
{
  width: 316px;
  margin-top: 45px;
}

.faqsearcharea
{
  width: 100%;
}

.faqserinparea
{
  width: 500px;
}

.faqserinparea_mb
{
  width: 316px;
}

.faqinp
{
  width: 500px;
  height: 45px;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
  padding-left: 15px;
  box-sizing: border-box;
}

.faqinp::placeholder
{
  border: none;
  font-size: 14px;
  color: #B3B3B3;
  font-weight: 400;
  font-family: 'noto';
}

.faqinp_mb
{
  width: 280px;
  height: 35px;
  border: none;
  padding-left: 15px;
  box-sizing: border-box;
}

.faqinp_mb::placeholder
{
  border: none;
  font-size: 14px;
  color: #B3B3B3;
  font-weight: 400;
  font-family: 'noto';
}

.faqinp:focus
{
  outline: none;
}

.faqserbtn
{
  width: 100px;
  height: 45px;
  border-radius: 5px;
  background-color: #4BB4DE26;
  border: none;
}

.faqserline
{
  width: 100%;
  height: 1px;
  background-color: #2056A5;
}

.faqbtn1
{
  min-width: 74px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #2056A5;
  background-color: #2056A51A;
}

.faqbtn1_mb
{
  width: 70px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid #2056A5;
  background-color: white;
}

.faqbtn2
{
  min-width: 74px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  background-color: white;
}

.faqbtn2_mb
{
  width: 70px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  background-color: white;
}

.faqitemarea
{
  width: 100%;
}

.faqitem
{
  width: 100%;
  height: 50px;
}

.faqitem11
{
  width: 100%;
}

.faqitem2
{
  width: 100%;
  min-height: 95px;
}

.faqitem3
{
  width: 100%;
}

.faqitemline
{
  width: 100%;
  height: 1px;
  background-color: #DADCE2;
}

.faqitemin
{
  height: 35px;
}

.faqans
{
  width: 230px;
}

.faqans_pc
{
  width: 1110px;
}