
.navibar
{
  width: 300px;
  min-width: 300px;
  background-color: #2056A51A;
  box-sizing: border-box;
}

.navimenu
{
  width: 74%;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.navimenu::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.navidesc
{
  width: 100%;
  height: 100px;
  background-color: #2056A51A;
}

.navidesccont
{
  width: 74%;
}

.nvloginbtn
{
  width: 100%;
  height: 30px;
  min-height: 30px;
  border-radius: 5px;
  border: none;
  background-color: #2056A5;
}

.nvline
{
  width: 100%;
  height: 1px;
  min-height: 1px;
  background-color: white;
}

.nvptbox
{
  width: 100%;
  min-height: 30px;
  border-radius: 5px;
  background-color: #2056A5;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}