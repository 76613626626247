
.unregbtn1
{
  width: 150px;
  height: 45px;
  border: none;
  border-radius: 5px;
  background-color: #2056A5;
  margin-right: 10px;
}

.unregbtn2
{
  width: 150px;
  height: 45px;
  border: none;
  border-radius: 5px;
  background-color: #CCCCCC;
}

.unregchk
{
  width: 15px;
  height: 15px;
  border: 1px solid #B3B3B3;
}