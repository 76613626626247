
.purbg
{
  width: 100%;
  background-color: white;
}

.purcont
{
  width: 1200px;
  margin-top: 70px;
}

.purcont_mb
{
  width: 316px;
  margin-top: 45x;
}

.purcont2
{
  width: 100%;
}

.purbank
{
  width: 622px;
}

.purbank_mb
{
  width: 100%;
}

.purbanksel
{
  width: 306px;
  height: 45px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
}

.purbanksel_mb
{
  width: 316px;
  height: 40px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
}

.purbankinp
{
  width: 306px;
  height: 45px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 15px;
}

.purbankinp::placeholder
{
  font-size: 16px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: 'noto';
}

.purbankinp_mb
{
  width: 316px;
  height: 40px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 15px;
}

.purbankinp_mb::placeholder
{
  font-size: 16px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: 'noto';
}

.purbankinp2
{
  width: 622px;
  height: 45px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 15px;
}

.purbankinp2::placeholder
{
  font-size: 16px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: 'noto';
}

.purbox1
{
  width: 32%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
}

.purbox11
{
  width: 49%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
}

.purbox1_mb
{
  width: 152px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
}

.purbox2
{
  width: 32%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #2056A5;
  background-color: #2056A51A;
}

.purbox22
{
  width: 49%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #2056A5;
  background-color: #2056A51A;
}

.purbox2_mb
{
  width: 152px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #2056A5;
  box-sizing: border-box;
}

.purbtn
{
  width: 622px;
  height: 45px;
  border: none;
  border-radius: 5px;
  background-color: #2056A5;
}

.purbtn_mb
{
  width: 316px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #2056A5;
}

.purchaselistbg
{
  width: 500px;
}

.purchaselistbg_mb
{
  width: 100%;
}

.purlistcont
{
  width: 500px;
  height: 655px;
  border-radius: 10px;

  box-shadow: 0px 0px 15px 0px #0000001A;
}

.purlisthead
{
  width: 500px;
  height: 45px;
  background-color: #4BB4DE26;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.purtt1
{
  width: 29%;
}

.purtt2
{
  width: 16%;
}

.purtt3
{
  width: 20%;
}

.purtt4
{
  width: 18%;
}

.purtt5
{
  width: 17%;
}

.purlistitem
{
  width: 100%;
  height: 53px;
}

.purlistitem_mb
{
  width: 100%;
  height: 54px;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
}

.purlistitem2_mb
{
  width: 95%;
}

.purlistitemline
{
  width: 480px;
  height: 1px;
  background-color: #DADCE2;
}

.purlistitemcont
{
  width: 100%;
  height: 540px;
  overflow-y: hidden;
}

.purlistitemcont_mb
{
  width: 100%;
}

.purstbg
{
  width: 70px;
  height: 30px;
  border-radius: 50px;
  background-color: #FFC800;
}

.purstbg_mb
{
  width: 50px;
  height: 20px;
  border-radius: 50px;
  background-color: #4BB4DE;
}

.purretbg
{
  width: 622px;
}

.purretbg_mb
{
  width: 316px;
}

.purretline
{
  width: 100%;
  height: 1px;
  background-color: #DADCE2;
}

.purretbtn
{
  width: 622px;
  height: 45px;
  border-radius: 5px;
  background-color: #2056A5;
  border: none;
}

.purretbtn_mb
{
  width: 316px;
  height: 40px;
  border-radius: 5px;
  background-color: #2056A5;
  border: none;
}

.purinfobg
{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0px;
  z-index: 5;
}

.purinfopop
{
  width: 500px;
  height: 475px;
  background-color: white;
  border-radius: 10px;
}

.purinfopop_mb
{
  width: 316px;
  height: 345px;
  background-color: white;
  border-radius: 10px;
}

.purinfocont
{
  width: 450px;
}

.purinfocont_mb
{
  width: 286px;
}

.purinfobox
{
  width: 450px;
  height: 100px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  padding-left: 10px;
}

.purinfobox_mb
{
  width: 286px;
  height: 80px;
  min-height: 80px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  padding-left: 10px;
}

.purinfobtn
{
  width: 450px;
  height: 45px;
  border-radius: 5px;
  background-color: #2056A5;
  border: none;
}

.purinfobtn_mb
{
  width: 100px;
  height: 25px;
  border-radius: 5px;
  background-color: #2056A5;
  border: none;
}

.purline
{
  width: 100%;
  height: 1px;
  background-color: #DADCE2;
}

.prpopbg2
{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 5;
}

.prpop2
{
  width: 675px;
  border-radius: 20px;
  background-color: white;

  padding-top: 25px;
  padding-bottom: 25px;
}

.prpop2in
{
  width: 92%;
}

.prpop2line
{
  width: 100%;
  height: 1px;
  background-color: #DADCE2;
}

.prpop2btn
{
  width: 100%;
  height: 45px;
  background-color: #2056A5;
  border-radius: 5px;
  border: none;
}