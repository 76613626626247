
.regmain
{
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.regleftcont
{
  width: 750px;
  min-height: 1080px;
  background-color: #4BB4DE26;
}

.regrightcont
{
  width: 1170px;
  min-height: 1080px;
  background-color: white;
}

.regleft
{
  min-width: 392px;
  width: 392px;
  min-height: 100%;
  height: 100%;
}

.regright
{
  width: 100%;
  min-width: 808px;
  min-height: 100%;
  height: 100%;
}

.regright_mb
{
  width: 316px;
  min-height: 100%;
  height: 100%;
  margin-bottom: 78px;
}

.regimg
{
  margin-top: 196px;
  margin-right: 50px;
}

.regtop
{
  width: 100%;
  height: 70px;
}

.regtop_mb
{
  width: 100%;
  height: 45px;
}

.regtoprt
{
  margin-left: 64%;
}

.regline
{
  width: 100%;
  height: 1px;
  background-color: #DADCE2;
}

.reginparea
{
  width: 550px;
  margin-top: 40px;
  margin-bottom: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 20px;
  border: 1px solid #F2F2F2;
}

.reginp
{
  width: 500px;
  min-height: 45px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  margin-top: 3px;
  box-sizing: border-box;
  padding-left: 15px;
}

.reginp::placeholder
{
  font-size: 16px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: 'noto';
}

.reginp_mb
{
  width: 316px;
  min-height: 40px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  margin-top: 3px;
  box-sizing: border-box;
  padding-left: 15px;
}

.reginp_mb::placeholder
{
  font-size: 14px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: 'noto';
}

.reginp2
{
  width: 390px;
  min-height: 45px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  margin-top: 3px;
  box-sizing: border-box;
  padding-left: 15px;
}

.reginp2::placeholder
{
  font-size: 16px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: 'noto';
}

.reginp2_mb
{
  width: 231px;
  min-height: 40px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  margin-top: 3px;
  box-sizing: border-box;
  padding-left: 15px;
}

.reginp2_mb::placeholder
{
  font-size: 14px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: 'noto';
}

.reginparea2
{
  width: 500px;
}

.reginparea2_mb
{
  width: 100%;
}

.regbtn
{
  width: 100px;
  min-height: 45px;
  background-color: #4BB4DE26;
  border: none;
  border-radius: 5px;
}

.regbtn_mb
{
  width: 80px;
  min-height: 40px;
  background-color: white;
  border: 1px solid #2056A5;
  border-radius: 5px;
}

.regbtn2
{
  width: 500px;
  min-height: 45px;
  background-color: #2056A5;
  border-radius: 5px;
  border: none;
}

.regbtn2_mb
{
  width: 316px;
  min-height: 40px;
  background-color: #2056A5;
  border-radius: 5px;
  border: none;
}

.regbox
{
  width: 390px;
  min-height: 45px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  margin-top: 3px;
  box-sizing: border-box;
  padding-left: 15px;
}

.regbox_mb
{
  width: 231px;
  min-height: 40px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  margin-top: 3px;
  box-sizing: border-box;
  padding-left: 15px;
}

.regimgselbtn
{
  cursor: pointer;
  width: 100px;
  height: 45px;
  background-color: #4BB4DE26;
  border-radius: 5px;
  border: none;
  text-align : center;
  vertical-align: middle;
}

.regimgselbtn_mb
{
  cursor: pointer;
  width: 80px;
  height: 40px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #2056A5;
  text-align : center;
  vertical-align: middle;
  box-sizing: border-box;
}

.regimgselinp
{
  cursor : pointer;
  width : 100px;
  height : 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  vertical-align: middle;
  text-align : center;
}

.regimgselinp_mb
{
  cursor : pointer;
  width : 80px;
  height : 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  vertical-align: middle;
  text-align : center;
}

.regfilestyle
{
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip:rect(0,0,0,0);
  border: 0;
}

.regchk
{
  width: 15px;
  height: 15px;
}

.regutbtnArea
{
  width: 500px;
  margin-top: 3px;
}

.regutbtnArea_mb
{
  width: 100%;
  margin-top: 3px;
}

.regutbtn1
{
  width: 245px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
}

.regutbtn2
{
  width: 245px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #2056A5;
  background-color: #2056A51A;
}

.regutbtn1_mb
{
  width: 48%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
}

.regutbtn2_mb
{
  width: 48%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #2056A5;
}