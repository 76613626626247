
.olbg
{
  width: 100%;
  background-color: white;
  margin-top: 70px;
}

.olbg_mb
{
  width: 100%;
  background-color: white;
  margin-top: 45px;
}

.olcont
{
  width: 1200px;
}

.olcont_mb
{
  width: 316px;
}

.olserarea
{
  width: 500px;
}

.olserarea_mb
{
  width: 100%;
}

.olserline
{
  width: 100%;
  height: 1px;
  background-color: #2056A5;
}

.olserinp
{
  width: 500px;
  height: 45px;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
  padding-left: 15px;
  box-sizing: border-box;
}

.olserinp::placeholder
{
  border: none;
  font-size: 14px;
  color: #B3B3B3;
  font-weight: 400;
  font-family: 'noto';
}

.olserinp:focus
{
  outline: none;
}

.olserinp_mb
{
  width: 230px;
  height: 35px;
  border: none;
  padding-left: 15px;
  box-sizing: border-box;
}

.olserinp_mb::placeholder
{
  border: none;
  font-size: 14px;
  color: #B3B3B3;
  font-weight: 400;
  font-family: 'noto';
}

.olserinp_mb:focus
{
  outline: none;
}

.oladstype
{
  width: 500px;
  min-height: 45px;
  box-sizing: border-box;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
  position: relative;
}

.oladstype_mb
{
  width: 316px;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
  position: relative;
}

.olbtn1
{
  width: 74px;
  height: 30px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #2056A5;
}

.olbtn1_mb
{
  width: 60px;
  height: 25px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #2056A5;
}

.olbtn2
{
  width: 74px;
  height: 30px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
}

.olbtn2_mb
{
  width: 60px;
  height: 25px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
}

.oltablabel
{
  width: 75px;
}

.olitemlist
{
  width: 100%;
  overflow: auto;
}

.olitembg
{
  width: 390px;
  height: 187px;
  border-radius: 10px;
  border: 1px solid #DADCE2;
  margin-right: 8px;
}

.olitembg_mb
{
  width: 316px;
  min-height: 174px;
  border-radius: 10px;
  border: 1px solid #DADCE2;
}

.olitembg2
{
  width: 356px;
  height: 157px;
}

.olitembg2_mb
{
  width: 298px;
  min-height: 155px;
}

.oliteminfobg
{
  width: 179px;
}

.olinfow
{
  width: 58px;
}

.olempty
{
  width: 100%;
  height: 150px;
}

.olempty_mb
{
  width: 100%;
  height: 70px;
}

.olasbg1_mb
{
  width: 50px;
  height: 30px;
  border-radius: 20px;
  background-color: #FFC800;
}

.olasbg2_mb
{
  width: 50px;
  height: 30px;
  border-radius: 20px;
  background-color: #B3B3B3;
}

.olasbg3_mb
{
  height: 30px;
  border-radius: 20px;
  background-color: #4BB4DE;
  padding-left: 5px;
  padding-right: 5px;
}

.olasbg4_mb
{
  width: 50px;
  height: 30px;
  border-radius: 20px;
  background-color: #00C3FF;
}

.olmyselbg
{
  width: 500px;
  height: auto;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  background-color: white;
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
}

.olmyselbg_mb
{
  width: 316px;
  height: 500px;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  background-color: white;
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
}

.olmyselbg2
{
  width: 100%;
  min-height: 30px;
}

.olmyselbg2:hover
{
  background-color: #B3B3B3;
}

.olmyselbgitem
{
  width: 100%;
  min-height: 34px;
}

.olmyselbgitem:hover
{
  background-color: #B3B3B3;
}

.olmyselbgline
{
  width: 97%;
  min-height: 1px;
  background-color: #DADCE2;
}

.olselbgmgl
{
  margin-left: 15px;
}

.olselbgmgr
{
  margin-right: 15px;
}

.olselclosebtn
{
  width: 100px;
  min-height: 25px;
  border-radius: 5px;
  border: none;
  background-color: #2056A5;
}

.odasbg
{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  z-index: 3;
}

.odasbgmb
{
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
  z-index: 5;
  left: 0px;
  top: 0px;
}

.odaspop
{
  width: 500px;
  height: 475px;
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
  padding-top: 20px;
}

.odaspopin
{
  width: 450px;
}

.odaspopin_mb
{
  width: 316px;
  margin-top: 45px;
}

.odasinp
{
  width: 100%;
  height: 200px;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  padding: 10px;
}

.odasinp::placeholder
{
  color: #B3B3B3;
}

.odasbtn
{
  width: 100%;
  height: 45px;
  background-color: #2056A5;
  border-radius: 5px;
  border: none;
  margin-top: 60px;
}

.odasbtnmb
{
  width: 100%;
  height: 40px;
  background-color: #2056A5;
  border-radius: 5px;
  border: none;
  margin-top: 50px;
}

.odasttmb
{
  width: 100%;
  height: 45px;
  background-color: white;
}

.odasttmbin
{
  width: 316px;
  height: 100%;
}

.odaslinemb
{
  width: 100%;
  min-height: 1px;
  background-color: #DADCE2;
}

.olstbtn
{
  width: 100px;
  height: 30px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
}

.olstbtn_mb
{
  width: 70px;
  height: 25px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
}

.olemptyarea
{
  width: 100%;
  height: 450px;
}

.odlsel
{
  width: 135px;
  height: 45px;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;
}

.odlserbtn
{
  width: 100px;
  height: 45px;
  border-radius: 5px;
  background-color: #4BB4DE26;
  border: none;
}

.odltable
{
  width: 100%;
  background-color: #F2F2F2;
  text-align: center;
  border-collapse: collapse;
  border : none;
  box-sizing: border-box;
}

.odltbcell
{
  min-height: 45px;
  height: 45px;
  border-left-width: 0px;
  border-top-width: 1px;
  border-bottom: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-color: #B3B3B3;
  box-sizing: border-box;
  text-align: center;
}

.odltbcell2
{
  min-height: 45px;
  height: 45px;
  border: 1px solid #B3B3B3;
  box-sizing: border-box;
  text-align: center;
}

.odltbcell3
{
  min-height: 45px;
  height: 45px;
  border-left-width: 1px;
  border-top-width: 1px;
  border-bottom: 1px;
  border-right-width: 0px;
  border-style: solid;
  border-color: #B3B3B3;
  box-sizing: border-box;
  text-align: center;
}

.odltbrowitem
{
  border-left-width: 0px;
  border-top-width: 1px;
  border-bottom: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-color: #B3B3B3;
}

.odltbrowitem2
{
  border-left-width: 1px;
  border-top-width: 1px;
  border-bottom: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-color: #B3B3B3;
}

.odltbrowitem3
{
  border-left-width: 1px;
  border-top-width: 1px;
  border-bottom: 1px;
  border-right-width: 0px;
  border-style: solid;
  border-color: #B3B3B3;
}

.odlnewodbtn
{
  width: 100px;
  height: 45px;
  border-radius: 5px;
  background-color: #4BB4DE26;
  border: none;
}