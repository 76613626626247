
.orderbg
{
  width: 100%;
}

.ordercont
{
  width: 1200px;
  margin-top: 100px;
}

.ordercont_mb
{
  width: 316px;
  margin-top: 45px;
}

.orderleft
{
  width: 670px;
  box-sizing: border-box;
  overflow: auto;
}

.orderleft_mb
{
  width: 100%;
  border-radius: 5px;
  background-color: #4BB4DE26;

  padding-top: 10px;
  padding-bottom: 10px;
}

.orderleft_in_mb
{
  width: 95%;
}

.orderleftcont1
{
  width: 670px;
  height: 240px;
  border-radius: 5px;
  background-color: #F2F2F280;
}

.orderleftcont2
{
  width: 670px;
  box-sizing: border-box;
  border: 1px solid #F2F2F2;
  border-radius: 10px;
  margin-bottom: 50px;
}

.orderleftcontin
{
  width: 640px;
}

.orderright
{
  width: 500px;
  border: 1px solid #F2F2F2;
  border-radius: 10px;
}

.orderright_mb
{
  width: 100%;
}

.orderrightcont
{
  width: 450px;
}

.orderrightcont_mb
{
  width: 100%;
}

.orderrightpfbtn1
{
  width: 105px;
  height: 70px;
  border: 1px solid #2056A5;
  border-radius: 5px;
}

.orderrightpfbtn1_mb
{
  width: 70px;
  height: 65px;
  border: 1px solid #2056A5;
  border-radius: 5px;
}

.orderrightpfbtn2
{
  width: 105px;
  height: 70px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
}

.orderrightpfbtn2_mb
{
  width: 70px;
  height: 65px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
}

.orderrightadstypebtn1
{
  height: 30px;
  border: 1px solid #2056A5;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.orderrightadstypebtn1_mb
{
  height: 25px;
  border: 1px solid #2056A5;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.orderrightadstypebtn2
{
  height: 30px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.orderrightadstypebtn2_mb
{
  height: 25px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.orderrightadstypecont
{
  width: 100%;
  flex-wrap: wrap;
}

.odrtsel
{
  width: 100px;
  height: 30px;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
  padding-left: 5px;
  box-sizing: border-box;
}

.odrtsel_mb
{
  width: 90px;
  height: 25px;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
  padding-left: 5px;
  box-sizing: border-box;
}

.odrtinp
{
  width: 450px;
  height: 45px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 10px;
}

.odrtinp::placeholder
{
  font-size: 14px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: 'noto';
}

.odrtinp_mb
{
  width: 100%;
  height: 40px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 10px;
}

.odrtinp_mb::placeholder
{
  font-size: 14px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: 'noto';
}

.odrtinp3
{
  width: 360px;
  height: 45px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 10px;
}

.odrtinp3::placeholder
{
  font-size: 14px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: 'noto';
}

.odrtinp3_mb
{
  width: 80%;
  height: 40px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 10px;
}

.odrtinp3_mb::placeholder
{
  font-size: 14px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: 'noto';
}

.odrchkbtn
{
  width: 80px;
  height: 45px;
  border: none;
  border: 1px solid #2056A5;
  border-radius: 5px;
  background-color: white;
}

.odrchkbtn_mb
{
  width: 19%;
  height: 40px;
  border: none;
  border: 1px solid #2056A5;
  border-radius: 5px;
  background-color: white;
}

.odrtodbtn
{
  width: 450px;
  min-height: 45px;
  border-radius: 5px;
  border: none;
  background-color: #2056A5;
}

.odrtodbtn_mb
{
  width: 316px;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: #2056A5;
}

.odrtodbtn_dis
{
  width: 450px;
  min-height: 45px;
  border-radius: 5px;
  border: none;
  background-color: #D9D9D9;
}

.odrtodbtn_dis_mb
{
  width: 316px;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: #D9D9D9;
}

.odleftlh
{
  line-height: 22px;
}

.odrptbtn1
{
  width: 100px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #2056A5;
  background-color: #2056A51A;
}

.odrptbtn1_mb
{
  width: 80px;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: #2056A5;
}

.odrptbtn2
{
  width: 100px;
  height: 45px;
  border-radius: 5px;
  border: none;
  background-color: #4BB4DE26;
}

.odrptbtn2_mb
{
  width: 80px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #2056A5;
  background-color: white;
}

.odrtinp2
{
  width: 80px;
  height: 40px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 10px;
}

.odrtinp2::placeholder
{
  font-size: 14px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: 'noto';
}

.odrtinp2_mb
{
  width: 80px;
  height: 40px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 10px;
}

.odrtinp2_mb::placeholder
{
  font-size: 14px;
  font-weight: 400;
  color: #B3B3B3;
  font-family: 'noto';
}

.odrptarea
{
  width: 450px;
}

.odrptarea_mb
{
  width: 100%;
  flex-wrap: wrap;
}

.odrptsel
{
  width: 99px;
  height: 45px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  text-align: center;
}

.odrptsel_mb
{
  width: 99px;
  height: 40px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  text-align: center;
}

.odguidepop
{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 5;
  left: 0px;
  top: 0px;
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
}

.odguidepoptop
{
  width: 100%;
  height: 45px;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 6;
  background-color: white;
}

.odguidepoptopin
{
  width: 316px;
  height: 100%;
}

.odmgdccont
{
  width: 95%;
}

.odmgdcimg
{
  width: 100%;
  object-fit: contain;
}

.odrdescmsg
{
  width: 450px;
  min-height: 70px;
  border-radius: 5px;
  border: none;
  background-color: #F2F2F280;
  box-sizing: border-box;
  padding: 10px;
}

.oddescimg
{
  object-fit: contain;
  width: 660px;
}