
.toastpop
{
  width: auto;
  max-width: 90%;
  min-height: 30px;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.8);
  background-color: rgba(0, 0, 0, 0.7);
  pointer-events: none;
  position: fixed;
  bottom: 70px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 10;
}