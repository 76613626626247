.ft_cont
{
  width: 100%;
  height: 100px;
  background-color: #F2F2F2;
}

.ft_cont2_mb
{
  width: 315px;
  min-width: 315px;
  height: 292px;
}

.ftleft
{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 150px;
  min-height: 150px;
}

.ftcenter
{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 150px;
  min-height: 150px;
}

.ftright
{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 150px;
  min-height: 150px;
}