.bingocont
{
  background-color: white;
}

.bingocont2
{
  width: 100%;
  margin-left: 40px;
  overflow: auto;
  box-sizing: border-box;
}

.bingoinp
{
  width: 500px;
  height: 45px;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
  padding-left: 10px;
  box-sizing: border-box;
}

.bingoinp::placeholder
{
  color: #B3B3B3;
}

.bingobtn1
{
  width: 100px;
  height: 45px;
  background-color: #4BB4DE26;
  border-radius: 5px;
  border: none;
}

.bingobtn2
{
  width: 220px;
  height: 45px;
  background-color: #2056A5;
  border-radius: 5px;
  border: none;
}

.bingobtn3
{
  width: 150px;
  height: 40px;
  background-color: #2056A51A;
  border-radius: 5px;
  border: 1px solid #2056A5;
}

.bingobtn4
{
  width: 150px;
  height: 40px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
}

.bingobtn5
{
  width: 100%;
  height: 40px;
  background-color: #2056A5;
  border-radius: 5px;
  border: none;
}

.bingobtn6
{
  width: 100px;
  height: 45px;
  background-color: #B3B3B3;
  border-radius: 5px;
  border: none;
}

.bingobtn7
{
  width: 340px;
  height: 45px;
  background-color: #2056A5;
  border-radius: 5px;
  border: none;
}

.bingobtn8
{
  width: 100px;
  height: 45px;
  background-color: #2056A51A;
  border-radius: 5px;
  border: none;
}

.bingobtn9
{
  width: 100%;
  height: 30px;
  background-color: #FFC8001A;
  border-radius: 5px;
  border: none;
}

.bingobtn10
{
  width: 100px;
  height: 45px;
  background-color: #4BB4DE26;
  border-radius: 5px;
  border: none;
}

.bingobtn11
{
  width: 585px;
  height: 45px;
  background-color: #2056A5;
  border-radius: 5px;
  border: none;
}

.bingobtn12
{
  width: 70px;
  height: 30px;
  background-color: #FF3636;
  border-radius: 20px;
  border: none;
}

.bingobtn13
{
  width: 70px;
  height: 30px;
  background-color: #2056A5;
  border-radius: 20px;
  border: none;
}

.bingobtn14
{
  width: 70px;
  height: 30px;
  background-color: #B3B3B3;
  border-radius: 20px;
  border: none;
}

.bingobtn15
{
  width: 70px;
  height: 30px;
  background-color: #FFC800;
  border-radius: 20px;
  border: none;
}

.bingotable
{
  width: 100%;
  background-color: #F2F2F2;
  text-align: center;
  border-collapse: collapse;
  border : none;
  box-sizing: border-box;
}

.bingotbcell
{
  min-height: 45px;
  height: 45px;
  border-left-width: 0px;
  border-top-width: 1px;
  border-bottom: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-color: #B3B3B3;
  box-sizing: border-box;
  text-align: center;
}

.bingotbcell2
{
  min-height: 45px;
  height: 45px;
  border: 1px solid #B3B3B3;
  box-sizing: border-box;
  text-align: center;
}

.bingotbcell3
{
  min-height: 45px;
  height: 45px;
  border-left-width: 1px;
  border-top-width: 1px;
  border-bottom: 1px;
  border-right-width: 0px;
  border-style: solid;
  border-color: #B3B3B3;
  box-sizing: border-box;
  text-align: center;
}

.bingodescbox1
{
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #F2F2F280;
  border-radius: 10px;
}

.bingocard1
{
  width: 290px;
  min-width: 290px;
  height: 529px;
  border: 1px solid #2056A5;
  background-color: #2056A51A;
  border-radius: 10px;
  margin-right: 15px;
}

.bingocard2
{
  width: 290px;
  min-width: 290px;
  height: 529px;
  border: 1px solid #B3B3B3;
  background-color: white;
  border-radius: 10px;
  margin-right: 15px;
}

.bingocardin
{
  width: 86%;
}

.bingotooltip
{
  width: 290px;
  height: 40px;
  min-height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
}

.bingoodleft
{
  width: 500px;
  min-width: 500px;
}

.bingoodleft2
{
  width: 585px;
}

.bingoodright
{
  width: 670px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid #F2F2F2;
  border-radius: 10px;
}

.bingoodbox1
{
  width: 100%;
  border: 1px solid #F2F2F2;
  border-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.bingoodbox2
{
  width: 100%;
  border-radius: 10px;
  background-color: #2056A51A;
  padding-top: 15px;
  padding-bottom: 15px;
}

.bingoodin
{
  width: 90%;
  box-sizing: border-box;
}

.bingoodin2
{
  width: 92%;
}

.bingoodin3
{
  width: 90%;
  box-sizing: border-box;
}

.bingoodinp
{
  width: 100%;
  height: 45px;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 10px;
}

.bingoodinp::placeholder
{
  color: #B3B3B3;
}

.bingoodtareabg
{
  width: 100%;
  height: 100px;
  border: 1px solid #B3B3B3;
  border-radius: 5;
}

.bingoodtarea
{
  width: 94%;
  height: 90%;
  box-sizing: border-box;
  margin: 0px;
  border: none;
}

.bingoodtarea:focus
{
  outline: none;
}

.bingoselbox1
{
  width: 85px;
  height: 30px;
  border-radius: 3px;
  background-color: #2056A51A;
  border: 1px solid #2056A5;
}

.bingoselbox2
{
  width: 85px;
  height: 30px;
  border-radius: 3px;
  background-color: white;
  border: 1px solid #B3B3B3;
}

.bingoselbox3
{
  width: 220px;
  height: 45px;
  border-radius: 3px;
  background-color: #2056A51A;
  border: 1px solid #2056A5;
}

.bingoselbox4
{
  width: 220px;
  height: 45px;
  border-radius: 3px;
  background-color: white;
  border: 1px solid #B3B3B3;
}

.bingomapbox
{
  width: 100%;
  height: 30px;
  background-color: #F2F2F2;
  border-radius: 3px;
}

.bingodatebox1
{
  width: 100%;
  height: 45px;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
}

.bingodateinp
{
  width: 430px;
  height: 100%;
  border: none;
}

.bingodateinp:focus
{
  outline: none;
}

.bingodateinp2
{
  width: 430px;
  height: 100%;
  border: none;
  box-sizing: border-box;
}

.bingodateinp2:focus
{
  outline: none;
}

.bingodescbox2
{
  width: 100%;
  height: auto;
  background-color: #F2F2F280;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.bingodescbox3
{
  width: 670px;
  height: auto;
  background-color: #2056A51A;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.bingodescbox4
{
  width: 100%;
  height: auto;
  background-color: #F2F2F280;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.bingodescbox5
{
  width: 100%;
  height: 30px;
  background-color: #F2F2F280;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.bingodescbox6
{
  width: 100%;
  min-height: 250px;
  background-color: #F2F2F280;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.bingodescbox7
{
  width: 100%;
  height: 30px;
  background-color: #2056A51A;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border: 1px solid #2056A5;
  border-radius: 20px;
}

.bingodescbox8
{
  width: 100%;
  height: 30px;
  background-color: #2056A51A;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border: none;
  border-radius: 20px;
}

.bingodescbox9
{
  width: 100%;
  height: 71px;
  background-color: #F2F2F280;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
}

.bingodescbox92
{
  width: 100%;
  min-height: 71px;
  background-color: #F2F2F280;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
}

.bingodescbox10
{
  width: 100%;
  height: 165px;
  background-color: #F2F2F280;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
}

.bingodescbox11
{
  width: 100%;
  height: 155px;
  background-color: #F2F2F280;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
}

.bingodescbox12
{
  width: 100%;
  height: 110px;
  background-color: #F2F2F280;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
}

.bingodescbox13
{
  width: 100%;
  min-height: 123px;
  background-color: #F2F2F280;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
}

.bingodescbox14
{
  width: 100%;
  min-height: 100px;
  background-color: #F2F2F280;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
}

.bingopay1
{
  width: 143px;
  height: 45px;
  border: 1px solid #2056A5;
  border-radius: 5px;
  background-color: #2056A51A;
}

.bingopay2
{
  width: 143px;
  height: 45px;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
  background-color: white;
}

.bingofilebox1
{
  width: 76%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  background-color: white;
}

.bingomnbnimg
{
  width: 316px;
  height: 120px;
  background-color: white;
}

.bingosbimg
{
  width: 100px;
  height: 100px;
  background-color: white;
}

.bingosmbnbimg
{
  width: 360px;
  height: 55px;
  background-color: white;
}

.bingorttextbox
{
  width: 517px;
}

.reodpopbg
{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  z-index: 5;
  top: 0px;
  left: 0px;
}

.reodpop
{
  width: 500px;
  border-radius: 20px;
  background-color: white;
  padding-top: 15px;
  padding-bottom: 15px;

}

.reodpopin
{
  width: 92%;
}

.reodpopdate
{
  width: 100%;
  height: 45px;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}

.reodpopbox
{
  width: 100%;
  height: 72px;
  background-color: #F2F2F280;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}

.reodpopboxtext
{
  width: 125px;
}

.reodbtn1
{
  width: 143px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #2056A5;
  background-color: #2056A51A;
}

.reodbtn2
{
  width: 143px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
}

.reodbtn3
{
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #2056A5;
  background-color: #2056A51A;
}

.reodbtn4
{
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: none;
  background-color: #2056A5;
}

.reodinp
{
  width: 100%;
  height: 45px;
  border: 1px solid #E6E6E6;
  border-radius: 5px;
  padding-left: 10px;
  box-sizing: border-box;
}

.reodinp::placeholder
{
  color: #B3B3B3;
}

.reoddescbox
{
  width: 100%;
  height: auto;
  background-color: #2056A51A;
  border-radius: 10px;
  padding: 15px 15px 15px 15px;
}

.bingohiddenfile
{
  display: none;
}

.bingorowdiv
{
  width: 100%;
  min-height: 45px;
  background-color: white;
  box-sizing: border-box;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bingonamebox
{
  width: 124px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #2056A5;
}

.bgpurchasedesc
{
  height: 30px;
  background-color: #ffebeb;
  border-radius: 5px;
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 1;
}

.bgodtext
{
  width: 135px;
}

.bgfnimg
{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bingoplaybg
{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 10;
}

.bingoplay
{
  width: 400px;
  height: 720px;
  background-color: #3f3f3f;
  border-radius: 20px;
}

.bingoplayview
{
  width: 360px;
  height: 640px;
  box-sizing: border-box;
  border: none;
}

.bpcdanibg
{
  width: 250px;
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding-bottom: 5px;
  box-sizing: border-box;
  margin-top: 10px;
}

.bpvdosbg1
{
  width: 100%;
  height: 30px;
}

.bpvdosbg2
{
  width: 100%;
  height: 30px;
}

.bgst1descimg
{
  width: 330px;
  height: 530px;
  object-fit: contain;
}

.bgst2img1
{
  width: 600px;
  height: 530px;
  object-fit: contain;
}

.bgst2img2
{
  width: 100%;
  height: 415px;
  object-fit: contain;
}

.bgst2img3
{
  width: 500px;
  height: 155px;
  object-fit: contain;
}

.bgst2img4
{
  width: 450px;
  height: 498px;
  object-fit: contain;
}

.bgst2img5
{
  width: 540px;
  height: 336px;
  object-fit: contain;
}

.bgst5img1
{
  width: 620px;
  height: 250px;
  object-fit: contain;
}

.bgst5img2
{
  width: 609px;
  height: 90px;
  object-fit: contain;
}

.bgst5img3
{
  width: 609px;
  height: 90px;
  object-fit: contain;
}

.bingood0box
{
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: rgba(255, 200, 0, 0.1);
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.bingood0btn1
{
  width: 85px;
  height: 30px;
  border-radius: 3px;
  border: none;
  background-color: #FF9600;
}

.bingood0btn2
{
  width: 100px;
  height: 45px;
  border-radius: 5px;
  border: none;
  background-color: #B3B3B3;
}

.bingood0btn3
{
  width: 220px;
  height: 45px;
  border-radius: 5px;
  border: none;
  background-color: #2056A5;
}


.bingood0left
{
  width: 650px;
}

.bingood0menubg1
{
  width: 100%;
  border: 1px solid #2056A5;
  background-color: rgba(32, 86, 165, 0.1);
  border-radius: 10px;
}

.bingood0menubg2
{
  width: 100%;
  border: 1px solid #F2F2F2;
  background-color: white;
  border-radius: 10px;
}

.bingood0menubght1
{
  height: 136px;
}

.bingood0menubght2
{
  height: 111px;
}

.bingood0menuin
{
  width: 94%;
  height: 100%;
}

.bingood0menudesc
{
  width: 100px;
}

.bingooddescsamplebg
{
  width: 450px;
  height: auto;
  background-color: rgba(242, 242, 242, 0.5);
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 80px;
}

.bingooddescsamplebgin
{
  width: 410px;
}

.bingooddescsamplebg2
{
  width: 410px;
  height: 290px;
  background-color: white;
  border-radius: 5px;
}

.bingosymboldescbox
{
  width: 450px;
  height: 164px;
  background-color: rgba(242, 242, 242, 0.5);
  border-radius: 5px;
  padding: 3.5%;
  box-sizing: border-box;
}

