
.termpopbg
{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  z-index: 10;
  background-color: rgba(0,0,0,0.5);
}

.termpop
{
  width: 1000px;
  height: 800px;
  max-height: 90%;
  background-color: white;
  border-radius: 10px;
}

.termpop_mb
{
  width: 100%;
  height: 100%;
  background-color: white;
}

.termtitle
{
  width: 100%;
}

.termtitle_mb
{
  width: 100%;
  height: 45px;
}

.termtitle_in_mb
{
  width: 316px;
  height: 45px;
}

.termcont
{
  width: 915px;
  height: 690px;
  overflow-x: hidden;
  overflow-y: auto;
}

.termcont_mb
{
  width: 90%;
  
  overflow-x: hidden;
  overflow-y: auto;
}

.termline
{
  width: 100%;
  height: 1px;
  background-color: #DADCE2;
}

.termbottom
{
  width: 100%;
  height: 100px;
  min-height: 100px;
}

.termtable
{
  width: 100%;
  border: 1px solid #DADCE2;
  border-collapse: collapse;
}

.termtablehead1
{
  width: 45%;
  min-height: 40px;
  background-color: #DADCE2;
  border: 1px solid #313131;
}

.termtablehead2
{
  width: 45%;
  min-height: 40px;
  background-color: #DADCE2;
  border: 1px solid #313131;
}

.termtablehead3
{
  width: 10%;
  min-height: 40px;
  background-color: #DADCE2;
  border: 1px solid #313131;
}

.termtabledata1
{
  width: 45%;
  min-height: 40px;
  border: 1px solid #313131;
  padding-left: 10px;
}

.termtabledata2
{
  width: 45%;
  min-height: 40px;
  border: 1px solid #313131;
  padding-left: 10px;
}

.termtabledata3
{
  width: 10%;
  min-height: 40px;
  border: 1px solid #313131;
  padding-left: 10px;
}